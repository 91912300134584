import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LazyTranslateLoader } from './app-translate-loader';
import { HttpQueryModule, SettingFactory, SettingsHttpService, TokenInterceptor } from '@unifonic/common';
import { CookieService } from 'ngx-cookie-service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { effectsModule, reducerProvider, storeDevtoolsModule, storeModule } from './store';
import { SettingsService } from '@shared/settings.service';
import { LayoutModule } from './layout/layout.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    HttpQueryModule,
    ...storeModule,
    ...effectsModule,
    ...storeDevtoolsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader,
      },
    }),
    SharedModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: SettingFactory,
      deps: [SettingsHttpService],
      multi: true,
    },
    CookieService,
    reducerProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: 'environment',
      useFactory: (settingsService: SettingsService) => settingsService.settings,
      deps: [SettingsService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
