import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApplicationConnectionsActions } from './applicationConnections.actions';
import { ConnectionAuditLog, ConnectionDetails, EventLogResponse } from '../../shared/applicationConnections/applicationConnections.models';
import { AppConnectionRepository } from '../../shared/applicationConnections/applicationConnections.repository';
import { of } from 'rxjs';
import { uniSnackbarActions } from '@unifonic/common/src';

@Injectable()
export class ConnectionsEffects {
  @Effect() setApplicationConnections$ = this.actions$.pipe(
    ofType(ApplicationConnectionsActions.SET_APPLICATION_CONNECTIONS),
    mergeMap(({ appId }) =>
      this.appConnectionRepository.getApplicationConnections(appId).pipe(
        map((response: ConnectionDetails[]) => new ApplicationConnectionsActions.SetAppConnectionsActionSuccess(response)),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );

  @Effect() setConnectionFullDetails$ = this.actions$.pipe(
    ofType(ApplicationConnectionsActions.SET_CONNECTION_FULL_DETAILS),
    mergeMap(({ appId, appConnectionId }) =>
      this.appConnectionRepository.getConnectionFullDetails(appId, appConnectionId).pipe(
        map((response: ConnectionDetails) => new ApplicationConnectionsActions.SetConnectionFullDetailsActionSuccess(response)),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );

  @Effect() setConnectionAuditLogs$ = this.actions$.pipe(
    ofType(ApplicationConnectionsActions.SET_CONNECTION_AUDIT_LOGS),
    mergeMap(({ appConnectionId }) =>
      this.appConnectionRepository.getConnectionAuditLogs(appConnectionId).pipe(
        map(
          (response: ConnectionAuditLog[]) =>
            new ApplicationConnectionsActions.SetConnectionAuditLogsActionSuccess(response, appConnectionId)
        ),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );

  @Effect() setConnectionEventLogs$ = this.actions$.pipe(
    ofType(ApplicationConnectionsActions.SET_CONNECTION_EVENT_LOGS),
    mergeMap(({ appConnectionId, pageSize, pageNumber }) =>
      this.appConnectionRepository.getConnectionEventLogs(appConnectionId, pageSize, pageNumber).pipe(
        map(
          (response: EventLogResponse) => new ApplicationConnectionsActions.SetConnectionEventLogsActionSuccess(response, appConnectionId)
        ),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );

  @Effect() setConnectionEventLogDetails$ = this.actions$.pipe(
    ofType(ApplicationConnectionsActions.SET_EVENT_LOG_DETAILS),
    mergeMap(({ eventId, appConnectionId }) =>
      this.appConnectionRepository.getEventLogDetails(eventId).pipe(
        map(
          (response: any) => new ApplicationConnectionsActions.SetConnectionEventLogDetailsActionSuccess(response, eventId, appConnectionId)
        ),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );

  @Effect() deleteConnection$ = this.actions$.pipe(
    ofType(ApplicationConnectionsActions.DELETE_CONNECTION),
    mergeMap(({ connectionId, appId }) =>
      this.appConnectionRepository.deleteConnection(connectionId).pipe(
        mergeMap(() => of(new ApplicationConnectionsActions.SetAppConnectionsAction(appId))),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );

  @Effect() editConnection$ = this.actions$.pipe(
    ofType(ApplicationConnectionsActions.EDIT_CONNECTION),
    mergeMap(({ connectionId, newName, appId }) =>
      this.appConnectionRepository.editConnection(connectionId, newName).pipe(
        mergeMap(() => of(new ApplicationConnectionsActions.SetConnectionFullDetailsAction(appId, connectionId))),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );

  constructor(private actions$: Actions, private appConnectionRepository: AppConnectionRepository) {}
}
