import { Action } from '@ngrx/store';
import { Application } from '../../shared/marketplace.models';
import { ConnectionAuditLog, ConnectionDetails, EventLogResponse } from '../../shared/applicationConnections/applicationConnections.models';

export namespace ApplicationConnectionsActions {
  export const SET_APPLICATION_CONNECTIONS = '[connections] SET_APPLICATION_CONNECTIONS';
  export const SET_APPLICATION_CONNECTIONS_SUCCESS = '[connections] SET_APPLICATION_CONNECTIONS_SUCCESS';
  export const SET_CONNECTION_FULL_DETAILS = '[connections] SET_CONNECTION_FULL_DETAILS';
  export const SET_CONNECTION_FULL_DETAILS_SUCCESS = '[connections] SET_CONNECTION_FULL_DETAILS_SUCCESS';
  export const SET_CONNECTION_AUDIT_LOGS = '[connections] SET_CONNECTION_AUDIT_LOGS';
  export const SET_CONNECTION_AUDIT_LOGS_SUCCESS = '[connections] SET_CONNECTION_AUDIT_LOGS_SUCCESS';
  export const SET_CONNECTION_EVENT_LOGS = '[connections] SET_CONNECTION_EVENT_LOGS';
  export const SET_CONNECTION_EVENT_LOGS_SUCCESS = '[connections] SET_CONNECTION_EVENT_LOGS_SUCCESS';
  export const SET_EVENT_LOG_DETAILS = '[connections] SET_EVENT_LOG_DETAILS';
  export const SET_EVENT_LOG_DETAILS_SUCCESS = '[connections] SET_EVENT_LOG_DETAILS_SUCCESS';
  export const RESET_APPLICATION_CONNECTIONS = '[connections] RESET_APPLICATION_CONNECTIONS';
  export const RESET_CONNECTIONS_EVENT_LOGS = '[connections] RESET_CONNECTIONS_EVENT_LOGS';
  export const DELETE_CONNECTION = '[connections] DELETE_CONNECTION';
  export const EDIT_CONNECTION = '[connections] EDIT_CONNECTION';

  // actions for getting application's connections
  export class SetAppConnectionsAction implements Action {
    readonly type = SET_APPLICATION_CONNECTIONS;
    constructor(public appId: Pick<Application, 'appId'>) {}
  }

  export class SetAppConnectionsActionSuccess implements Action {
    readonly type = SET_APPLICATION_CONNECTIONS_SUCCESS;
    constructor(public connectionDetailsArr: ConnectionDetails[]) {}
  }

  export class SetConnectionFullDetailsAction implements Action {
    readonly type = SET_CONNECTION_FULL_DETAILS;
    constructor(public appId: Pick<Application, 'appId'>, public appConnectionId: string) {}
  }

  export class SetConnectionFullDetailsActionSuccess implements Action {
    readonly type = SET_CONNECTION_FULL_DETAILS_SUCCESS;
    constructor(public connectionFullDetails: ConnectionDetails) {}
  }

  export class SetConnectionAuditLogsAction implements Action {
    readonly type = SET_CONNECTION_AUDIT_LOGS;
    constructor(public appConnectionId: string) {}
  }

  export class SetConnectionAuditLogsActionSuccess implements Action {
    readonly type = SET_CONNECTION_AUDIT_LOGS_SUCCESS;
    constructor(public connectionAuditLogArr: ConnectionAuditLog[], public appConnectionId: string) {}
  }

  export class SetConnectionEventLogsAction implements Action {
    readonly type = SET_CONNECTION_EVENT_LOGS;
    constructor(public appConnectionId: string, public pageSize: number, public pageNumber: number) {}
  }

  export class SetConnectionEventLogsActionSuccess implements Action {
    readonly type = SET_CONNECTION_EVENT_LOGS_SUCCESS;
    constructor(public eventLogResponse: EventLogResponse, public appConnectionId: string) {}
  }

  export class SetConnectionEventLogDetailsAction implements Action {
    readonly type = SET_EVENT_LOG_DETAILS;
    constructor(public eventId: string, public appConnectionId: string) {}
  }

  export class SetConnectionEventLogDetailsActionSuccess implements Action {
    readonly type = SET_EVENT_LOG_DETAILS_SUCCESS;
    constructor(public eventDetails: any, public eventId: string, public appConnectionId: string) {}
  }

  export class ResetAppConnections implements Action {
    readonly type = RESET_APPLICATION_CONNECTIONS;
    constructor() {}
  }

  export class ResetConnectionEventLogs implements Action {
    readonly type = RESET_CONNECTIONS_EVENT_LOGS;
    constructor(public appConnectionId: string) {}
  }
  export class deleteConnectionAction implements Action {
    readonly type = DELETE_CONNECTION;
    constructor(public connectionId: string, public appId: string) {}
  }

  export class editConnectionAction implements Action {
    readonly type = EDIT_CONNECTION;
    constructor(public connectionId: string, public newName: string, public appId: string) {}
  }

  export type All =
    | SetAppConnectionsAction
    | SetAppConnectionsActionSuccess
    | SetConnectionFullDetailsAction
    | SetConnectionFullDetailsActionSuccess
    | SetConnectionAuditLogsAction
    | SetConnectionAuditLogsActionSuccess
    | SetConnectionEventLogsAction
    | SetConnectionEventLogsActionSuccess
    | SetConnectionEventLogDetailsAction
    | SetConnectionEventLogDetailsActionSuccess
    | ResetAppConnections
    | ResetConnectionEventLogs
    | deleteConnectionAction
    | editConnectionAction;
}
